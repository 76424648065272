<template>
  <div class="container">
    <b-row>
      <img :src="imageUrl" style="width: 1200px; height: 650px;">
    </b-row>
    <br/>
    <b-row v-if="role == 1">
      <b-form @submit.prevent="handleSubmit" enctype="multipart/form-data">
        <b-form-file
          id="image"
          v-model="selectedImage"
          accept=".png"
          class="form-control-file"
        ></b-form-file>
        <br/>
        <br/>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    axios,
  },
  data() {
    return {
      role: localStorage.getItem('role'),
      imageUrl: null,
      selectedImage: null,
      base64Image: null
    };
  },
  methods: {
    init(){
      this.getImg();
    },
    getImg(){
      axios.post("idp/master/radio-img/list",{'id':1}).then((response) => {
        // this.imageUrl = response.data.img
        this.imageUrl = response.data.img;
      }).catch((error) => {
      }).finally(() => {
        this.Loading = false;
      });
    },
    handleSubmit() {
      
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedImage);
      reader.onload = () => {
        const base64Image = reader.result; // Menyimpan data Base64 untuk dikirim

        const formData = new FormData();
        formData.append('id', 1); // Menggunakan nama yang sesuai dengan yang diharapkan oleh API
        formData.append('img', this.selectedImage); // Menggunakan nama yang sesuai dengan yang diharapkan oleh API

        axios.post("idp/master/radio-img/edit", formData).then((response) => {
            this.$swal({
              title: "Success",
              icon: "question",
              background: "black",
              confirmButtonColor: "#7367f0",
              confirmButtonText: "Close",
            });
          })
          .catch((error) => {
            console.error('Error:', error);
            // alert('Terjadi kesalahan saat mengunggah gambar.');
          })
          .finally(() => {
            this.init();
          });
      };
    },
  },
  mounted(){
    this.init();
  }
};
</script>